import React, { useState } from 'react';

interface CheckboxProps {
    slideId: number
    check: boolean | undefined;
    onCheckChange: (slideId: number, check: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({slideId,  onCheckChange, check }) => {
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(check));

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    onCheckChange(slideId, checked);
  };

  return (
    <label>
      <input 
        type="checkbox" 
        checked={isChecked} 
        onChange={handleCheckboxChange} 
      />
    </label>
  );
};

export default Checkbox;
