import React, { useState } from 'react';
import './FileUpload.css';

interface FileUploadProps {
  slideId: number;
  file: File | null;
  onFileUpload: (slideId: number, file: File | null) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ slideId, onFileUpload, file }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      if (selectedFile.size > 10485760) {  // 10MB limit
        setErrorMessage("Bestand is groter dan 10MB. Upload een kleiner bestand.");
        onFileUpload(slideId, null);
      } else {
        setErrorMessage(null);
        onFileUpload(slideId, selectedFile);  // Pass file back to parent component
      }
    }
  };

  return (
    <div className="file-upload">
      {file && (
        <span className="file-info">
          {file.name}
        </span>
      )}
      <label className="upload-button" htmlFor={`file-input-${slideId}`}>
        {file ? "Wijzig bestand" : "Upload bestand"}
      </label>
      <input
        id={`file-input-${slideId}`}
        type="file"
        onChange={handleFileChange}
        accept="image/*, .pdf"
      />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default FileUpload;
