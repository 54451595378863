import { useEffect, useState } from 'react';
import './App.css';
import NavButton from './components/nav-button/NavButton';
import { Slide as SlideType } from './types/types';
import Slide from './components/slide/Slide';
import logo from './image.png'
import { getSlides } from './services/contentService';

interface UploadData {
  [slideId: number]: File | null;
}

interface CheckData {
  [slideId: number]: boolean
}

const App = () => {
  const [slides, setSlides] = useState<SlideType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSlides();
      const slides = response?.map(entry => (
        {
          ...entry.fields
        })
      )
      setSlides(slides);
    };

    fetchData();
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [uploads, setUploads] = useState<UploadData>({});
  const [checks, setChecks] = useState<CheckData>({})

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const canEnableSubmit = () => {
    const allChecks = slides.filter(slide => slide.withCheckbox).every(slide => checks[slide.id])
    const allFiles = slides.filter(slide => slide.withCheckbox).every(slide => checks[slide.id])
    return allChecks && allFiles
  }

  const handleFileUpload = (slideId: number, file: File | null) => {
    setUploads((prevUploads) => ({
      ...prevUploads,
      [slideId]: file,
    }));
    setSubmitDisabled(canEnableSubmit())
  };

  const handleCheckChange = (slideId: number, check: boolean) => {
    setChecks((prevChecks) => ({
      ...prevChecks,
      [slideId]: check,
    }));
    setSubmitDisabled(canEnableSubmit())
  };
  
  const handleSubmit = () => {
    console.log("Submitting the following uploads:", uploads);
  
    // You can send uploads to a mock API
    // Example: Call your AWS API Gateway here with the uploads
  };

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <div className="App">
      <img className ="Logo" src={logo} />
      {slides.length && <div className="slider">
        <Slide
          slide={slides[currentSlide]}
          onFileUpload={handleFileUpload}
          onCheckChange={handleCheckChange}
          file={uploads[slides[currentSlide].id]}
          check={checks[slides[currentSlide].id]}
        />
      </div>}

      {/* Navigation Buttons */}
      <div className="buttons">
        {currentSlide !== 0 && <NavButton className="Nav-previous" onClick={prevSlide} disabled={currentSlide === 0} />}
        {currentSlide < slides.length - 1 && <NavButton className="Next" onClick={nextSlide} disabled={currentSlide === slides.length - 1} />}
      </div>

      {/* Show final submit button only on the last slide */}
      {currentSlide === slides.length - 1 && (
        <div className="submit-section">
          <h3>Samenvatting</h3>
          <ul>
          {slides.filter(slide => slide.withCheckbox).map((slide) => (
              <li key={slide.id}>
                <strong>{slide.title}:</strong>{" "}
                {checks[slide.id] ? "Afgegeven" : "Niet afgegeven"}
              </li>
            ))}
            {slides.filter(slide => slide.withUpload).map((slide) => (
              <li key={slide.id}>
                <strong>{slide.title}:</strong>{" "}
                {uploads[slide.id] ? uploads[slide.id]?.name : "Nog geen bestand geupload"}
              </li>
            ))}
          </ul>
          <button className="submit-button" onClick={handleSubmit} disabled={submitDisabled} title={submitDisabled ? "Benodigde info nog niet binnen" : "Versturen"}> 
            Verstuur 
          </button>
        </div>
      )}

    </div>
  );
};

export default App;
