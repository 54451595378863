import React from 'react';
import FileUpload from '../file-upload/FileUpload';
import {Slide as SlideType} from '../../types/types';
import Checkbox from '../checkbox/checkbox';
import ReactMarkdown from 'react-markdown';

interface SlideProps {
  slide: SlideType;
  file: File | null;
  check: boolean | undefined;
  onFileUpload: (slideId: number, file: File | null) => void;  // File upload handler prop
  onCheckChange: (slideId: number, check: boolean) => void
}

const Slide: React.FC<SlideProps> = ({ slide, file, check, onFileUpload, onCheckChange}) => {
  return (
    <div className="slide">
      <h2>{slide.title}</h2>
      <ReactMarkdown>{slide.content}</ReactMarkdown>
      {slide.withCheckbox && <Checkbox slideId={slide.id} onCheckChange={onCheckChange} check={check}/>}
      {/* Conditionally render the FileUpload component based on uploadEnabled */}
      {slide.withUpload && <FileUpload slideId={slide.id} onFileUpload={onFileUpload} file={file}/>}
    </div>
  );
};

export default Slide;
