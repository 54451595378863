import React from 'react';
import './NavButton.css';

// Define the props for the Button component
interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

// Create the Button component
const NavButton: React.FC<ButtonProps> = ({ onClick, disabled = false, className }) => {
  return (
    <button className={`Nav-button ${className}`} disabled={disabled} onClick={onClick}><img className="Nav-image" src={require("./asset/button.png")}/>
    </button>
  );
};

export default NavButton;
