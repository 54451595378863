// src/services/contentService.ts
import { createClient, EntryFieldTypes, FieldsType } from 'contentful';

export interface EntryFields {
  contentTypeId : 'slide',
  fields: {
    id: EntryFieldTypes.Integer
    title: string;
    content: string;
    withUpload?: boolean;
    withCheckbox?: boolean;
  }
}

export const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
});

export const getSlides = async () => {
  try {
    const entries = await client.getEntries<EntryFields>({
      content_type: 'slide',
      order: ['fields.id']
    });
    return entries.items;
  } catch (error) {
    console.error('Error fetching content:', error);
    return [];
  }
};
